import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function GraniteColors() {
  const data = useStaticQuery(graphql`
    query GraniteColorsQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "granite-color" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Granite Colors"
      title="Granite Colors Gallery | Sheboygan Monument &amp; Stone Works"
      description="Granite colors that we have available."
      urlPath="granite-colors"
    />
  );
}
